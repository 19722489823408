import { Injectable, inject, signal } from '@angular/core';
import {
  Auth,
  EmailAuthProvider,
  PhoneAuthProvider,
  RecaptchaVerifier,
  User,
  authState,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getMultiFactorResolver,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from '@angular/fire/auth';
import {
  Firestore,
  setDoc,
  doc,
  collectionData,
  collection,
  where,
  updateDoc,
  query,
} from '@angular/fire/firestore';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { nanoid } from 'nanoid';
import { LocalstorageService } from '@services/localstorage.service';
import { FirebaseErrors } from '@models/firebaseErrors';
import { LanguageService } from './language.service';
import { EMPTY, Observable, map } from 'rxjs';
import { AbonnementService } from './abonnement.service';
import { Functions, httpsCallable } from '@angular/fire/functions';
import KRGlue from '@lyracom/embedded-form-glue';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth = inject(Auth);
  router = inject(Router);
  firestore = inject(Firestore);
  functions = inject(Functions);
  _snackBar = inject(MatSnackBar);
  abonnementService = inject(AbonnementService);
  languageService = inject(LanguageService);
  localStorageService = inject(LocalstorageService);

  collectionUsers = collection(this.firestore, 'users');

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  public readonly user$: Observable<User | null> = EMPTY;

  uid: string = '';
  email: string = '';
  error: string = '';
  authState: any = null;
  restoUID: any;

  constructor() {
    if (this.auth) {
      this.user$ = authState(this.auth);

      authState(this.auth)
        .pipe(map((u) => !!u))
        .subscribe((isLoggedIn) => {
          if (isLoggedIn) {
            const user = this.auth.currentUser;
            this.uid = user!.uid;
            this.localStorageService.setItem('user', JSON.stringify(user));
            JSON.parse(this.localStorageService.getItem('user')!);
          } else {
            this.uid = '';
            this.localStorageService.setItem('user', 'null');
            JSON.parse(this.localStorageService.getItem('user')!);
          }
        });
    }
  }

  get isAuthenticated(): boolean {
    return this.authState !== null;
  }

  checkUserState() {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        return true;
      } else {
        return false;
      }
    });
  }

  getUserID() {
    return this.auth.currentUser;
  }

  showError() {
    if (this.languageService.language() == 'fr') {
      this._snackBar.open('Identifiant ou mot de passe incorrect', '', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 5000,
        panelClass: 'errorSnack',
      });
    } else if (this.languageService.language() == 'en') {
      this._snackBar.open('Incorrect username or password', '', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 5000,
        panelClass: 'errorSnack',
      });
    }
  }

  reLoginError() {
    if (this.languageService.language() == 'fr') {
      this._snackBar.open("Echec lors de l'authentification", '', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 5000,
        panelClass: 'errorSnack',
      });
    } else if (this.languageService.language() == 'en') {
      this._snackBar.open('Failed during authentication', '', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 5000,
        panelClass: 'errorSnack',
      });
    }
  }

  updatePwd() {
    if (this.languageService.language() == 'fr') {
      this._snackBar.open('Mot de passe mis à jour avec succès', '', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 5000,
        panelClass: 'successSnack',
      });
    } else if (this.languageService.language() == 'en') {
      this._snackBar.open('Password updated successfully', '', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 5000,
        panelClass: 'successSnack',
      });
    }
  }

  showErrorPwd() {
    if (this.languageService.language() == 'fr') {
      this._snackBar.open('Echec lors de la mise à jour du mot de passe', '', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 5000,
        panelClass: 'errorSnack',
      });
    } else if (this.languageService.language() == 'en') {
      this._snackBar.open('Failed to update password', '', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 5000,
        panelClass: 'errorSnack',
      });
    }
  }

  sendEmailCheckOK() {
    if (this.languageService.language() == 'fr') {
      this._snackBar.open(
        'Un email de vérification vient de vous être envoyé',
        '',
        {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: 5000,
          panelClass: 'successSnack',
        }
      );
    } else if (this.languageService.language() == 'en') {
      this._snackBar.open(
        'A verification email has just been sent to you',
        '',
        {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: 5000,
          panelClass: 'successSnack',
        }
      );
    }
  }

  // User

  errorMsg = null;
  private verificationIDSignal = signal(null);
  readonly verificationID = this.verificationIDSignal.asReadonly();

  resolver: any;

  async loginUser(email: string, password: string) {
    return await signInWithEmailAndPassword(this.auth, email, password)
      .then(() => {
        this.router.navigate(['home']);
      })
      .catch(async (error) => {
        if (error.code == 'auth/multi-factor-auth-required') {
          this.errorMsg = error.code;

          this.resolver = getMultiFactorResolver(this.auth, error);

          const recaptchaVerifier = new RecaptchaVerifier(
            this.auth,
            'recaptcha-container-id',
            {
              size: 'invisible',
              callback: function () {},
            }
          );

          const phoneInfoOptions = {
            multiFactorHint: this.resolver.hints[0],
            session: this.resolver.session,
          };

          const phoneAuthProvider = new PhoneAuthProvider(this.auth);
          phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then((verificationId: any) => {
              this.verificationIDSignal.set(verificationId);
            });
        } else {
          this.showError();
        }
      });
  }

  async logOutUser() {
    return await signOut(this.auth).then(() => {
      this.router.navigate(['home']);
    });
  }

  async registerWithSubscription(
    email: string,
    password: string,
    nom: string,
    prenom: string,
    descriptionPrix: string,
    prixFinal: any,
    codeForBDD: string,
    duree: number,
    language: any
  ) {
    await createUserWithEmailAndPassword(this.auth, email, password).then(
      async (data) => {
        const profil = 'utilisateurs';
        const idDoc = nanoid();

        // calcul date

        function addDays(date: Date, days: number): Date {
          const result = new Date(date);
          result.setDate(date.getDate() + days);
          return result;
        }

        const originalDate = new Date();
        const newDate = addDays(originalDate, duree);

        const dateEffect = newDate.toISOString().slice(0, -5) + '+00:00';

        const newUser = {
          nom,
          prenom,
          email,
          idDoc,
          profil,
          descriptionPrix,
          prixFinal,
          codeForBDD,
          duree,
          language
        };

        const uid = data.user.uid;

        await setDoc(doc(this.firestore, 'users', uid), newUser);

        // Si code

        if (codeForBDD) {

          const data = {
            uid: uid,
            code: codeForBDD

          };

          const updateAccount = await httpsCallable(this.functions, 'updateuserAccountRegisterWithCode');
          updateAccount(data);

          // const subscription = {
          //   currency: 'EUR',
          //   formAction: 'REGISTER',
          //   orderId: uid,
          //   customer: {
          //     Reference: 'Abonnement',
          //     email: email,
          //     billingDetails: {
          //       firstName: nom,
          //       lastName: prenom,
          //     },
          //   },
          //   subMerchantDetails: {
          //     name: 'Detectable',
          //     address1: dateEffect,
          //     address2: prixFinal === null ? 499 : prixFinal,
          //     softDescriptor: codeForBDD,
          //   },
          // };

          // const tokenCall = await httpsCallable(this.functions, 'createToken');
          // tokenCall(subscription).then(async (result) => {
          //   const formToken: any = result.data;

          //   const endpoint = 'https://api.payzen.eu';
          //   const publicKey =
          //     '77756374:publickey_L5J2rHNHueNntcgW25S5a1J4Dc5jBvSNQICF9sUij9Rw7';

          //   await KRGlue.loadLibrary(endpoint, publicKey)
          //     .then(({ KR }) =>
          //       KR.setFormConfig({
          //         'kr-post-url-success': 'https://app.detectable.fr/home',
          //         formToken: formToken,
          //         'kr-language': 'fr-FR',
          //       })
          //     )
          //     .then(({ KR }) => KR.button.setLabel('0€'))
          //     .then(({ KR }) => KR.renderElements('#myPaymentForm'));
          // });
        } else {
          const subscription = {
            amount: 499,
            currency: 'EUR',
            formAction: 'REGISTER',
            orderId: uid,
            customer: {
              Reference: 'Abonnement',
              email: email,
              billingDetails: {
                firstName: nom,
                lastName: prenom,
              },
            },
            subMerchantDetails: {
              name: 'Detectable',
              address1: dateEffect,
              address2: prixFinal === null ? 499 : prixFinal,
              softDescriptor: codeForBDD,
            },
          };
          const tokenCall = await httpsCallable(this.functions, 'createToken');
          tokenCall(subscription).then(async (result) => {
            const formToken: any = result.data;

            const endpoint = 'https://api.payzen.eu';
            const publicKey =
              '77756374:publickey_L5J2rHNHueNntcgW25S5a1J4Dc5jBvSNQICF9sUij9Rw7';

            await KRGlue.loadLibrary(
              endpoint,
              publicKey
            ) /* Load the remote library */
              .then(({ KR }) =>
                KR.setFormConfig({
                  /* set the minimal configuration */
                  'kr-post-url-success': 'https://app.detectable.fr/home',
                  formToken: formToken,
                  'kr-language': 'fr-FR',
                })
              )
              .then(({ KR }) => KR.button.setLabel('4.99€'))
              .then(({ KR }) =>
                KR.renderElements('#myPaymentForm')
              ); /* add a payment form  to myPaymentForm div*/
          });
        }
      }
    );
  }

  getAuth() {
    return this.auth.onAuthStateChanged;
  }

  resetPwd(code: any, pwd: any) {
    confirmPasswordReset(this.auth, code, pwd)
      .then(async () => {
        window.open('https://app.detectable.fr/login', '_self');
      })
      .catch((err) => {
        const errorMessage = FirebaseErrors.Parse(err.code);
      });
  }

  getUserInfo(userUID: any) {
    return collectionData(
      query(this.collectionUsers, where('userUID', '==', userUID))
    );
  }

  async updateProfil(userUID: any, profil: any) {
    await updateDoc(doc(this.firestore, 'users', userUID), profil);
  }

  async reauthenticate(user: any, email: any, pwd: any) {
    const cred = EmailAuthProvider.credential(email, pwd);

    await reauthenticateWithCredential(user, cred);
  }

  async updatepwd(user: any, password: any) {
    await updatePassword(user, password)
      .then(() => {
        this.updatePwd();
      })
      .catch(() => {
        this.showErrorPwd();
      });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(this.localStorageService.getItem('user')!);
    if (user == null) {
      return false;
    } else {
      return true;
    }
  }
}
