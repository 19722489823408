import { Injectable, inject } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  Firestore,
  collection,
  collectionData,
  doc,
  updateDoc,
} from '@angular/fire/firestore';
import KRGlue from '@lyracom/embedded-form-glue';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class AbonnementService {
  functions = inject(Functions);
  firestore = inject(Firestore);
  languageService = inject(LanguageService);
  _snackBar = inject(MatSnackBar);

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  getSubscriptionState(userUID: any) {
    return collectionData<any>(
      collection(this.firestore, 'users', userUID, 'subscription')
    );
  }

  async subscribe(userUID: any) {
    const activateAbo = await httpsCallable(
      this.functions,
      'activateSubscription'
    );
    activateAbo(userUID).then(async (result: any) => {

      if (result.data.subscriptionId) {
        const statut = { statut: 'success' };
        const subscriptionId = { subscriptionId: result.data.subscriptionId };

        await updateDoc(
          doc(this.firestore, 'users', userUID, 'subscription', userUID),
          statut
        );

        await updateDoc(
          doc(
            this.firestore,
            'users',
            userUID,
            'subscription',
            userUID,
            'firstPay',
            userUID
          ),
          subscriptionId
        );

        if (this.languageService.language() == 'fr') {
          this._snackBar.open('Abonnement activé avec succès', '', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 3000,
            panelClass: 'successSnack',
          });
        } else if (this.languageService.language() == 'en') {
          this._snackBar.open('Subscription successfully activated', '', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 3000,
            panelClass: 'successSnack',
          });
        }
      } else if (!result.data.subscriptionId) {

        const formToken: any = result.data;

        const endpoint = 'https://api.payzen.eu';
        const publicKey =
          '77756374:publickey_L5J2rHNHueNntcgW25S5a1J4Dc5jBvSNQICF9sUij9Rw7';

        await KRGlue.loadLibrary(
          endpoint,
          publicKey
        )
          .then(({ KR }) =>
            KR.setFormConfig({
              /* set the minimal configuration */
              'kr-post-url-success': 'https://app.detectable.fr/home',
              formToken: formToken,
              'kr-language': 'fr-FR',
            })
          )
         // .then(({ KR }) => KR.button.setLabel('4.99€'))
          .then(({ KR }) =>
            KR.renderElements('#myPaymentForm')
          );
      } else {
        if (this.languageService.language() == 'fr') {
          this._snackBar.open(
            'Une erreur est survenue lors de la procédure',
            '',
            {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: 3000,
              panelClass: 'errorSnack',
            }
          );
        } else if (this.languageService.language() == 'en') {
          this._snackBar.open('An error occurred during the procedure', '', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 3000,
            panelClass: 'errorSnack',
          });
        }
      }
    });
  }

  async unSubscribe(userUID: any) {
    const cancelAbo = await httpsCallable(this.functions, 'cancelSubscription');
    cancelAbo(userUID).then(async (result: any) => {
      if (result.data.responseCode == 0) {
        const statut = { statut: 'Annulé' };

        await updateDoc(
          doc(this.firestore, 'users', userUID, 'subscription', userUID),
          statut
        );

        if (this.languageService.language() == 'fr') {
          this._snackBar.open('Abonnement désactivé avec succès', '', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 3000,
            panelClass: 'successSnack',
          });
        } else if (this.languageService.language() == 'en') {
          this._snackBar.open('Subscription successfully deactivated', '', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 3000,
            panelClass: 'successSnack',
          });
        }
      } else {
        if (this.languageService.language() == 'fr') {
          this._snackBar.open(
            'Une erreur est survenue lors de la procédure',
            '',
            {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: 3000,
              panelClass: 'errorSnack',
            }
          );
        } else if (this.languageService.language() == 'en') {
          this._snackBar.open('An error occurred during the procedure', '', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 3000,
            panelClass: 'errorSnack',
          });
        }
      }
    });
  }

  async goToPayZenForm(
    userUID: any,
    email: any,
    nom: any,
    prenom: any,
    finalCode: any,
    prixFinal: any,
    codeForBDD: any
  ) {
    const subscription = {
      amount: 499,
      currency: 'EUR',
      formAction: 'REGISTER',
      orderId: userUID,
      customer: {
        Reference: 'Abonnement',
        email: email,
        billingDetails: {
          firstName: nom,
          lastName: prenom,
        },
      },
      subMerchantDetails: {
        name: 'Detectable',
        address1: finalCode === null ? null : finalCode,
        address2: prixFinal === null ? 499 : prixFinal,
        softDescriptor: codeForBDD,
      },
    };

    const tokenCall = await httpsCallable(this.functions, 'createToken');
    tokenCall(subscription).then(async (result) => {
      const formToken: any = result.data;

      const endpoint = 'https://api.payzen.eu';
      const publicKey =
        '77756374:publickey_L5J2rHNHueNntcgW25S5a1J4Dc5jBvSNQICF9sUij9Rw7';

      await KRGlue.loadLibrary(
        endpoint,
        publicKey
      ) /* Load the remote library */
        .then(({ KR }) =>
          KR.setFormConfig({
            /* set the minimal configuration */
            'kr-post-url-success': 'https://app.detectable.fr/mon-compte',
            formToken: formToken,
            'kr-language': 'fr-FR' /* to update initialization parameter */,
          })
        )
        .then(({ KR }) =>
          KR.renderElements('#myPaymentForm')
        ); /* add a payment form  to myPaymentForm div*/
    });
  }

  // Code

  getCode() {
    return collectionData(collection(this.firestore, 'code'));
  }
}
